
.ScrollBars::-webkit-scrollbar {
  position: relative;
  width: 10px; /* Adjust the width of the scrollbar */
}

.ScrollBars::-webkit-scrollbar-track {
  background: #E2E2E2; /* Background of the scrollbar track */
  border-radius: 10px;
  margin-left: 10px;
}

.dropdown-buttonss:focus {
  outline: none;
  border-color: #4779EF;
}

.dropdown-contentss {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 4px;
}



.ScrollBars::-webkit-scrollbar-thumb {
  background-color: #278ef1; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 2px solid #E2E2E2; /* Border around the scrollbar thumb */
}

/* Change thumb color on hover */
.ScrollBars::-webkit-scrollbar-thumb:hover {
  background-color: #1b6fb2; /* Darker blue color on hover */
}

/* Change thumb color on active (click) */
.ScrollBars::-webkit-scrollbar-thumb:active {
  background-color: #0a4e8a; /* Even darker blue color on active */
}

.dropdown-containerss {
  width: 16.5vw;
  margin-top: 0.5vw;
  margin-left: 0.5vw;
  position: relative;
  display: inline-block;

}

/* Style for the dropdown button */
.dropdown-buttonss {
  height: 30px;
  width: 16.5vw;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

/* Style for the down arrow */
.down-arrowss {
  position: absolute;
  right: 10px;

}

/* Style for the dropdown content */
.dropdown-contentss {
  width: 16.5vw;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  font-size: 14px;
  z-index: 1600;
  scrollbar-width: thin;
  scrollbar-color: #278ef1 #E2E2E2;
  scroll-behavior: smooth;
}

/* Style for the scrollable list */
.scrollable-listss {
  padding: 10px;
}

/* Style for individual list items */
.scrollable-listss > div {
  padding: 5px;
  cursor: pointer;
  margin-left: -0.5vw;
  
}

/* Style for the selected item in all dropdowns */
.scrollable-listss > div[selected] {
  background: #278ef1;
  color: #fff;
}
.filterButton{
  width: 180px;
  height: 48px;
  border-radius: 40px;
  margin-top: -10vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 8px;
    font-weight: 800;
}
.right{
  margin-left: 6vw;
}
.icon{
  width:50px !important;
  height: 70px !important;
}
.icon-container{
  width: 20%;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #6F6868;
}
.sessionContainer{
  border-radius: 8px;
  box-shadow: 0px 3px 6px #0000000A;
  display: flex;
  margin-left: 21vw;
  padding: 10px;
  width: 32vw;
  height: 135px;
}
.container-fonts{
  font-size: 18px;
  margin-left: 20px;
  font-weight: 600;
}
.version-fonts1{
  font-size: 14px;
  margin-left: 20px;
  margin-top: 10px;
}
.version-fonts2{
  font-size: 14px;
  margin-left: 20px;
  margin-top: 4px;
}
.version-fonts3{
  font-size: 14px;
  margin-top: 20px;
  margin-left: 16px;
  white-space: nowrap;
}
.user-container{
  width: 32vw;
  height: 74px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #0000000A;
  display: flex;
  margin-left: 21vw;
  padding: 10px;
}
.user-fonts{
  font-size: 16px;
}
.sessionNote-Container{
  width: 16.5vw;
  min-width: 25%;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #0000000A;
  position: relative;
  right: -19px;
  margin-top: 3.5vh;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #278ef1 #E2E2E2;
  scroll-behavior: smooth;
}

.sessionNote-Container-admin {
  height: 28vh;
}

.sessionNote-Container-super_admin {
  height: 39vh;
}

.sessionNote-Container-organization
{
  width: 16.5vw;
  min-width: 25%;
  height: 197px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #0000000A;
  position: relative;
  right: -19px;
  margin-top: 2vh;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #278ef1 #E2E2E2;
  scroll-behavior: smooth;
}
.font-weight{
  font-weight: 600;
}
.add-icon{
 color: #4779EF;
 cursor: pointer;
 box-shadow: #0000004D;
 font-size: 30px;
}
.sessionTitle{
  width: 95%;
  height: 25px;
  border-radius: 2px;
  margin-top: -10px;
  border: 1px solid #DFDFDF;
}
.metric-grid{
  font-size: 13px;
 }
.sess-appname{
  font-size: 16px;
}
#scroll-fontss{
  font-size: 12px;
}
.scroll-icon{
  font-size: 20px !important;
  margin-top: 2px;
}
.sessDrop-container {
  /* width: 200px; */
  margin-top: 0.5vw;
  /* margin-left: 0.5vw; */
  position: relative;
  display: inline-block;

}
.sessDrop-button {
  height: 30px;
  width: 210px;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.sessDrop-content {
  width: 210px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  display: block;
  font-size: 12px;
  z-index: 1600;
}


@media only screen and (min-device-width: 750px) 
and (max-device-width: 899px) 
{
  
  .sessDrop-button {
    height: 30px;
    width: 145px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .sessDrop-content {
    width: 145px;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    display: block;
    font-size: 12px;
    z-index: 1600;
  }  
  #scroll-fontss{
    font-size: 9px;
  }
  .scroll-icon{
    font-size: 13px !important;
    margin-bottom: -17px;
  }
  .sess-appname{
    font-size: 10px;
  }
  .metric-grid{
    font-size: 8px;
   }
  .sessionTitle{
    width: 136px;
    height: 20px;
  }
  .font-weight{
    font-size: 12px;
  }
  .add-icon{
    width: 200px !important;
  height: 20px !important;
    margin-top: 12px;
    margin-right: -90px;
  }
  
  .sessionNote-Container{
    width: 22vw;
    height: 151px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #0000000A;
    position: relative;
    right: -17px;
    margin-top: 2vh;
    padding: 10px;
  }
  .version-fonts1{
    font-size: 12px;
    margin-left: 15px;
  }
  .version-fonts2{
    font-size: 11px;
    margin-left: 15px;
  }
  .version-fonts3{
    font-size: 8px;
    margin-top: 30px;
    margin-left: 8px;
  }
  .container-fonts{
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
  }
  .sessionContainer{
    border-radius: 8px;
    box-shadow: 0px 3px 6px #0000000A;
    display: flex;
    margin-left: 21vw;
    padding: 10px;
    width: 37vw;
    height: 125px;
  }
  /* .right{
    margin-left: 90px;
  } */
  .icon-container{
    width: 15%;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #6F6868;
  }
  .icon{
    font-size: 70;
  }
  .filterButton{
    width: 160px;
    height: 30px;
    border-radius: 60px;
    margin-top: -12vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 4px;
      font-weight: 800;
  }
  .user-container{
    width: 37vw;
    height: 55px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #0000000A;
    display: flex;
    margin-left: 21vw;
    padding: 10px;
  }
  .user-fonts{
    font-size: 9px;
  }
  
  /* .Container{
  color: #ffffff;
  width: 100px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left:10px;
 }

  .BlueBox{
      color: #FFFFFF;
      background: #278EF1;
      border-radius: 8px;
   } */

  .dropdown {
      margin: 0 ;
      z-index: 1000;
      width: 200px;
      position: relative;
      height: 20%;
    }
    .dropdown-buttonss {
      height: 30px;
      width: 180px;
      cursor: pointer;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    }
    
    .dropdown-contentss {
      width: 180px;
      position: absolute;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      max-height: 200px;
      overflow-y: auto;
      display: block;
      font-size: 14px;
      z-index: 1600;
    }

}

@media only screen and (min-device-width: 900px) 
and (max-device-width: 1200px) 
{
  .user-fonts{
    font-size: 12px;
  }
.sessDrop-button {
  height: 30px;
  width: 126px;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.sessDrop-content {
  width: 126px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  display: block;
  font-size: 12px;
  z-index: 1600;
}  
  .scroll-icon{
    font-size: 12px !important;
  }
  .font-weight{
    font-size: 12px;
  }
  .add-icon{
    width: 200px !important;
  height: 20px !important;
    margin-top: 12px;
    margin-right: -90px;
  }
  
  #scroll-fontss{
    font-size: 11px;
  }
  .sess-appname{
    font-size: 12px;
  }
  .metric-grid{
   font-size: 10px;
  }
  .version-fonts1{
    font-size: 12px;
    margin-left: 15px;
  }
  .version-fonts2{
    font-size: 11px;
    margin-left: 15px;
  }
  .version-fonts3{
    font-size: 9px;
    margin-top: 30px;
    margin-left: 8px;
  }
  .container-fonts{
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
  }
  .sessionContainer{
    border-radius: 8px;
    box-shadow: 0px 3px 6px #0000000A;
    display: flex;
    margin-left: 21vw;
    padding: 10px;
    width: 30vw;
    height: 125px;
  }
  /* .right{
    margin-left: 90px;
  } */
  .icon-container{
    width: 15%;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #6F6868;
  }
  .icon{
    font-size: 70;
  }
  .filterButton{
    width: 160px;
    height: 30px;
    border-radius: 60px;
    margin-top: -12vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 4px;
      font-weight: 800;
  }

  .Container{
  color: #ffffff;
  width: 100px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left:10px;
 }

  .BlueBox{
      color: #FFFFFF;
      background: #278EF1;
      border-radius: 8px;
   }

  .dropdown {
      margin: 0 ;
      z-index: 1000;
      width: 258px;
      position: relative;
      height: 20%;
    }
  
    .dropdown .dropdown-btn {
  
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px;
      background: white;
      border-radius: 20px;
      border: 1px solid white;
      color: #777;
      font-weight: 400;
      justify-content:space-between;
  
    }

}

@media only screen and (min-device-width: 1201px) 
and (max-device-width: 1400px) {
  .user-fonts{
    font-size: 13px;
  }
  .sessDrop-button {
    height: 30px;
    width: 186px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .sessDrop-content {
    width: 186px;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    display: block;
    font-size: 12px;
    z-index: 1600;
  }  
  .scroll-icon{
    font-size: 15px !important;
  }
  .Centergrid{
    padding-top: "50px";
    margin-left: "300px"
  }

  .Container{
      width: 150px;
  padding: 15px;
  padding-bottom: 0px;
  margin-left:10px;
  
  }
  .BlueBox{
      color: #FFFFFF;
      background: #278EF1;
      border-radius: 8px;
      margin-left: 20px; 
    }
  .dropdown {
  
      margin: 0 ;
      z-index: 1000;
      width: 258px;
      position: relative;
      height: 20%;
  
    }
  
    .dropdown .dropdown-btn {
  
      cursor: pointer;
      /* background: blue; */
      display: flex;
      align-items: center;
      padding: 10px;
      background: white;
      border-radius: 20px;
      border: 1px solid white;
      color: #777;
      font-weight: 400;
      justify-content:space-between;
  
    }

}

@media only screen and (min-device-width: 1401px) 
and (max-device-width: 1700px) 
{
  .scroll-icon{
    font-size: 22px !important;
  }
  .add-icon{
    width: 400px !important;
    height: 25px !important;
    margin-top: 5px;
    margin-right: -190px;
  }
  .font-weight{
    font-size: 17px;
  }
  .metric-grid{
    font-size: 12px;
   }
.Centergrid{
  margin-top: "-400px";
  margin-left: "200px"
}
.Container{
  color: #FFFFFF;
    width: 250px;
padding-top: 0px;
padding-bottom: 0px;
margin-left:10px;

}
.BlueBox{
    color: #FFFFFF;
    background: #278EF1;
    border-radius: 8px;
 
}
.dropdown {

    margin: 0 ;
    z-index: 1000;
    width: 258px;
    position: relative;
    height: 20%;

  }

  .dropdown .dropdown-btn {

    cursor: pointer;
    /* background: blue; */
    display: flex;
    align-items: center;
    padding: 10px;
    background: white;
    border-radius: 20px;
    border: 1px solid white;
    color: #777;
    font-weight: 400;
    justify-content:space-between;

  }
}

@media only screen and (min-device-width: 1701px) 
and (max-device-width: 2500px)

{
  .user-fonts{
    font-size: 18px;
  }
  .sessDrop-button {
    height: 30px;
    width: 255px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .sessDrop-content {
    width: 255px;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    display: block;
    font-size: 12px;
    z-index: 1600;
  }  
  .scroll-icon{
    font-size: 27px !important;
  }
  .metric-grid{
    font-size: 12px;
   }
    .Container{
      
    width: 300px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left:10px;
    
    }
    .BlueBox{
        color: #FFFFFF;
        background: #278EF1;
        border-radius: 8px;
     
    }
    .dropdown {

        margin: 0 ;
        z-index: 1000;
        width: 258px;
        position: relative;
        height: 20%;
    
      }
    
      .dropdown .dropdown-btn {
    
        cursor: pointer;
        /* background: blue; */
        display: flex;
        align-items: center;
        padding: 10px;
        background: white;
        border-radius: 20px;
        border: 1px solid white;
        color: #777;
        font-weight: 400;
        justify-content:space-between;
    
      }
  

}
.custom-button {
  margin-top: 7vh;
  margin-left: -11vw;
  height: 25px;
  width: 150px;
  background-color: #4779EF;
  /* padding: 10px; */
  border-radius: 5px;
  color: white;
  font-size: 12px;
  /* font-weight: 600; */
  cursor: pointer;
  border: none;
  outline: none;
}