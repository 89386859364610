/* Custom class to style the dropdown list */
.custom-dropdown-list {
    width: 180px; /* Adjust the width as needed */
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #4779EF;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

/* Hide scrollbars for the main page */
