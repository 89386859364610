/* .ScrollBars {
    scrollbar-width: thin;
    scrollbar-color: #278ef1 #E2E2E2;
  } */
  .fonts{
    font-size: 12px;
  }
  .request-button{
    height: 34px;
    width: 120px;
    background-color: #4779EF;
    color: #FFFFFF;
    border-radius: 8px;
    float: right;
    cursor: pointer;
    border: none;
    margin-top: -12px;
    margin-right: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    outline: none;
  }
  .headertitle{
    font-size: 14px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.proglabel{
  /* margin-left: 13vw; */
  /* margin-Top: "-4vh", */
  font-size: 12px;
}
.performance-trends{
  width:49.5vw;
}
.app-name{
  font-size: 15px;
}
#scroll-fonts{
    font-size: 12px;
}
#scroll-fonts1{
  font-size: 12px;
  margin-left: 55%;
  margin-top: -18px;
}
#scroll-fonts3{
  font-size: 12px;
  margin-left: 55%;
  margin-top: -18px;
}

  @media only screen and (min-device-width: 1000px) and (max-device-width: 1299px) { 
    #scroll-fonts{
      font-size: 12px;
    }
    #scroll-fonts1{
      font-size: 12px;
      margin-left: 15px;
      margin-top: 0px;
    }
    #scroll-fonts3{
      font-size: 12px;
      margin-left: 11px;
      margin-top: 0px;
    }
    #scroll-orgfonts{
      font-size: 12px;
      margin-left: 11px;
    }
    .app-name{
      font-size: 13px;
    }
    .request-button{
    height: 34px;
    width: 100px;
    background-color: #4779EF;
    color: #FFFFFF;
    border-radius: 8px;
    margin-left: 11vw;
    float: right;
    margin-top: -12px;
    cursor: pointer;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    outline: none;
    
  }
  .performance-trends{
    width:49vw;
  }
  .fonts{
    font-size: 11px;
  }
  .number{
    font-size: 11px;
    left: 1px;
    display: flex;
    margin-top: -10px;
  }
  .number1{
    font-size: 11px;
    left: -13px;
    display: flex;
    margin-top: -10px;
  }
  .proglabel{
    /* margin-left: 12vw; */
    /* margin-Top: "-3vh", */
    font-size: 11px;
  }
  .headertitle{
    font-size: 12px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    padding-bottom: 3px;
    margin-bottom: 5px;
    }
    
 }
 
@media only screen and (min-device-width: 750px) and (max-device-width:999px) {
  #scroll-fonts1{
    font-size: 8px;
    margin-left: 15px;
    margin-top: 0px;
  }
  #scroll-fonts3{
    font-size: 8px;
    margin-left: 15px;
    margin-top: 0px;
  }
  #scroll-orgfonts{
    font-size: 8px;
    margin-left: 15px;
  }
  #scroll-fonts{
    font-size: 8px;
  }
  .app-name{
    font-size: 12px;
  }
  .request-button{
    height: 20px;
    width: 70px;
    background-color: #4779EF;
    color: #FFFFFF;
    border-radius: 8px;
    margin-left: -1vw;
    float: right;
    /* margin-top: -12px; */
    cursor: pointer;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    outline: none; 
    font-size: 8px;
  }
  .performance-trends{
    width:48.5vw;
  }
  .system-information {
    height: 260px;
    /* margin-top: -10px; */
    padding-top: 10;
    margin-right: 5%;
    /* float: right; */
    border-radius: 10px;
    background: white;
    width: 30%;
  }
  .headertitle{
    font-size: 8px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    padding-bottom: 3px;
    margin-bottom: 5px;
    }
    .proglabel{
      /* margin-left: 11vw; */
      /* margin-Top: "-4vh", */
      font-size: 8px;
    }
  .para {
    margin-top: "-40px";
    margin-left: "25%";
    font-weight: "lighter";
  }

  .system-informations {
    /* height: 50px; */
    /* margin-top: ; */
    /* margin-right: 50px; */
    box-shadow: "5px 5px 5px silver";
    border-radius: 10px;
    background: rgb(251, 251, 251);
    width: "5%";
  }

  .icons {
    margin-left: 130px;
  }

  .add {
    margin-left: 260px;
    /* margin-top: "-50px"; */
  }

  .session-note {
    border-radius: 20px;
    width: 5%;
    box-shadow: "5px 5px 5px silver";
    margin-top: "20px";

    background-color: white;
  }

  .button-container {
    display: flex;
    flex-direction: row;
  }

  .buttons {
    margin-right: 10px;
    width: 2px;
  }

  .app-info-sub {
    padding-top: 20px;
    margin-top: 45px;
    font-size: 14px;
    font-weight: 300;
    margin-left: -10px;
    padding-left: -5px;
    color: #070707;
  }
  .device-para-style {
    padding-top: 2%;
    font-size: 16px;
    margin-left: 48px;
  }
  .app-info-image {
    width: 12%;
    object-fit: contain;
    float: left;
    margin-left: 5%;
    margin-top: -10%;
    margin-bottom: 3%;
  }
  .device-info-text {
    margin-top: -20px;
    margin-right: 100px;
    font-weight: "lighter";
  }
  .fonts{
    font-size: 7px;
  }
  .number{
    font-size: 7px;
    left: 1px;
    display: flex;
    margin-top: -16px;
  }
  .number1{
    font-size: 7px;
    left: -13px;
    display: flex;
    margin-top: -14px;
  }
}
@media only screen and (min-device-width: 1200px) and (max-device-width: 1700px) {
  #scroll-fonts1{
    font-size: 12px;
    /* margin-left: 15px;
    margin-top: 0px; */
  }
  #scroll-orgfonts{
    font-size: 12px;
    margin-left: 10px;
  }
  .icons {
    margin-left: 180px;
  }

  .addicon {
    margin-top: "-30px";
  }

  /* .para{
  margin-top: "100px";
  margin-left: "55";
  font-weight: "lighter";
} */

  .add {
    /* margin-top: -10px; */
    margin-left: 280px;
  }

  .system-informations {
    /* height: 50px; */
    /* margin-top: ; */
    /* margin-right: 50px; */
    box-shadow: "5px 5px 5px silver";
    border-radius: 10px;
    background: rgb(251, 251, 251);
    width: "5%";
  }

  .device-info-style {
    width: 200px;
    height: auto;
    margin-top: 0%;
    border-radius: 10px;
    background: white;
  }
  .device-info-style-list {
    width: 100%;
    /* height: 12vh; */
    /* margin-top: 0%; */
    /* border-radius: 10px; */
    /* color: white;
  /* background: white; */
  }

  /* .session-note{
  border-radius:'20px';
  box-shadow: "5px 5px 5px silver";
  margin-top:"20px";
  width:"100px";
  background-color:white;
}
 */

  .system-information {
    /* height: 280px;
  margin-top: 60px;
  margin-right: 5%;
  border-radius: 10px;
  background: white;
  width: 30%; */

    height: "15%";
    /* margin-top: ; */
    /* margin-right: 50px; */
    border-radius: 10px;
    background: white;
    width: "5%";
  }
  .name-info-style {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  .name-info-image {
    width: 15%;
    object-fit: contain;
    float: left;
  }

  .app-info-style {
    width: 100%;
    margin-top: 4%;
    height: auto;
    border-radius: 10px;
    color: black;
    background: white;
  }

  .app-info-sub > p {
    font-size: 14px;
    font-weight: 300;
    padding-left: 7%;
    color: #278ef1;
  }

  .device-info-sub {
    padding: 1%;
    padding-top: 0.5%;
    padding-left: 7%;
  }
  .name-info-sub {
    padding: 1.5%;
  }
  .device-para-style {
    padding-top: 2%;
    font-size: 16px;
    margin-left: 48px;
  }
  .device-divider-style {
    color: white;
    margin-top: -2%;
    height: 1px;
    border-width: 0;
  }

  .app-divider-style {
    color: white;
    margin-top: -2%;

    height: 1px;
    border-width: 0;
  }

  .app-info-image {
    width: 12%;
    object-fit: contain;
    float: left;
    margin-left: 5%;
    margin-top: 1%;
    margin-bottom: 3%;
  }

  .device-info-image {
    margin-top: 1%;
    width: 16%;

    object-fit: contain;
    float: left;
    border-radius: 19px;
  }
  .device-info-text {
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 700;
    margin-top: 2%;
  }
  .name-info-text {
    padding-left: 4%;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 600;
    margin-top: 2%;
    color: #278ef1;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 15px;
  }
/* 
  .metric-usage {
    background: white;
    border-radius: 10px;
    padding: 5%;
    width: 34%;
    height: auto;
    margin: auto;
    box-shadow: 0px 1px 10px #0000001a;
    float: right;
    color: black;
    font-weight: 200;
  } */

  .MuiTypography-colorTextSecondary { 
    color: none !important;
  }
}
@media only screen and (min-device-width: 1300px) and (max-device-width: 1450px){
  #scroll-fonts3{
    font-size: 12px;
    margin-left: 11px;
    margin-top: 0px;
  }
}
@media only screen and (min-device-width: 1500px) and (max-device-width: 1699px) {
  .request-button{
  height: 34px;
  width: 120px;
  background-color: #4779EF;
  color: #FFFFFF;
  border-radius: 8px;
  margin-left: 3.5vw;
  float: right;
  /* margin-top: 5px; */
  cursor: pointer;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  outline: none;
}
}
@media only screen and (min-device-width: 1700px) and (max-device-width: 2500px) {
  .org-appname{
    font-size: 17px;
  }
  #scroll-orgfonts{
    font-size: 14px;
    margin-left: 11px;
  }
  #scroll-fonts{
    font-size: 14px;
}
#scroll-fonts1{
  font-size: 14px;
}

  .request-button{
    height: 34px;
    width: 120px;
    background-color: #4779EF;
    color: #FFFFFF;
    border-radius: 8px;
    margin-left: 14vw;
    float: right;
    /* margin-top: 1px; */
    cursor: pointer;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    outline: none;
  }
  .device-info-style {
    width: 200px;
    height: auto;
    margin-top: 10px;
    border-radius: 10px;
    background: white;
  }

  .nam {
    width: 10px;
    border-radius: 20px;
    background-color: aqua;
  }

  .app-info-sub > p {
    font-size: 14px;
    font-weight: 300;
    padding-left: 7%;

    color: #278ef1;
  }

  .device-para-style {
    padding-top: 2%;
    font-size: 16px;
    margin-left: 48px;
  }
  .device-info-text {
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 700;
    margin-top: 2%;
  }
  .app-info-image {
    width: 12%;
    object-fit: contain;
    float: left;
    margin-left: 5%;
    margin-top: 1%;
    margin-bottom: 3%;
  }
  .system-information {
    height: "15%";
    /* margin-top: ; */
    /* margin-right: 50px; */
    border-radius: 10px;
    background: white;
    width: "5%";
  }

  .icons {
    margin-left: 320px;
  }

  .add {
    margin-left: 460px;
    /* margin-top: "-50px"; */
  }

  .system-informations {
    /* height: 50px; */
    /* margin-top: ; */
    /* margin-right: 50px; */
    border-radius: 10px;
    background: rgb(251, 251, 251);
    width: "5%";
  }

  /* .metric-usage {
    background: white;
    border-radius: 10px;
    padding: 5%;
    width: 25%;
    height: auto;
    margin: auto;
    box-shadow: 0px 1px 10px #0000001a;
    float: right;
    color: black;
    font-weight: 200;
  } */
  .fonts{
    font-size: 14px;
  }
  .number{
    font-size: 14px;
    left: 1px;
    display: flex;
    margin-top: -10px;
  }
  .number1{
    font-size: 14px;
    left: -13px;
    display: flex;
    margin-top: -10px;
  }
}

.admin-containers {
  margin-right: 15px;
  flex: 0 0 auto;
  width: 200px;

}

.admin-dropdown-button {
  width: 100%;
  height: 30px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-button {
  height: 30px;
  width: 120px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  background-color: #4779EF;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-content {
  position: absolute;
  width: 200px;
  background: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1600;
  max-height: 200px;
  overflow-y: auto;
  font-size: small;
}


.admin-list {
  padding: 20px;
  margin-top: -15px;
  padding-bottom: 15px;
}