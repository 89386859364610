.dates {
  margin-left: 0;
}
 
/* Container for aligning dropdowns in a row */
.dropdowns-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 1vh;
  justify-content: space-between; /* Added to distribute space */
}
 
.dropdown-containers-org {
  display: inline-flex;
  margin: 0.5vw;
  position: relative;
}
 
/* New container for session and metrics dropdowns */
.session-metrics-container {
  display: flex;
  flex-direction: column;
  margin: 0.5vw;
  position: relative;
  margin-left: auto; /* Push to right end */
}
 
/* For positioning metrics dropdown in the same line */
.dropdown-container-metrics {
  display: inline-flex;
  margin: 0.5vw;
  position: relative;
  margin-left: auto; /* Push to right end */
}
 
/* Style for the dropdown button */
.dropdown-buttons {
  height: 30px;
  width: 125px;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
 
/* Style for the down arrow */
.down-arrows {
  position: absolute;
  right: 10px;
}
 
/* Style for the dropdown content */
.dropdown-contents-org {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  font-size: 12px;
  z-index: 1600;
  scrollbar-width: thin;
  scrollbar-color: #278ef1 #E2E2E2;
  scroll-behavior: smooth;
}
 
/* Style for the scrollable list */
.scrollable-lists {
  padding: 10px;
}
 
/* Style for individual list items */
.scrollable-lists > div {
  padding: 5px;
  cursor: pointer;
  margin-left: 2vw;
}
 
/* Style for the selected item in all dropdowns */
.scrollable-lists > div[selected] {
  background: #278ef1;
  color: #fff;
}
 
/* CSS for custom buttons */
.custom-buttons {
  height: auto;
  width: 150px;
  background-color: #4779EF;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 0.5vw;
}
 
/* Updated style for metrics dropdown */
.metrics-org {
  background-color: #4779EF;
  width: 100%;
  max-height: auto;
  font-size: clamp(10px, 1vw, 10px);
  color: black;
  font-weight: 200;
}
 
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}
 
.MuiTypography-colorTextSecondary {
  color: none !important;
}
 
.cards-title {
  font-size: 14px;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
 
.card3-header {
  flex: 1;
  text-align: left;
  font-size: 14px;
  margin: 1vh;
}
 
.card3-content {
  font-size: 12px;
  font-weight: 600;
}
 
.org-fonts {
  font-size: 11px;
  margin-left: 15px;
  white-space: nowrap;
}
 
.org-content1 {
  font-size: 12px;
  position: relative;
  top: 20px;
}
 
.org-content2 {
  font-size: 12px;
  position: relative;
  top: 20px;
}
 
.org-content3 {
  font-size: 12px;
  position: relative;
  top: 20px;
}
 
.orgprog-label {
  margin-left: 13vw;
  margin-top: -3vh;
}
 
.org-appname {
  font-size: 15px;
}
 
/* Media queries with updated positioning for metrics dropdown */
@media only screen and (min-device-width: 750px) and (max-device-width: 1019px) {
  .org-appname {
    font-size: 12px;
  }
 
  .orgprog-label {
    margin-left: 11vw;
    margin-top: -1.7vh;
  }
 
  .org-fonts {
    font-size: 5px;
  }
 
  .org-content1, .org-content2, .org-content3 {
    font-size: 6px;
    position: relative;
    top: 15px;
  }
 
  .cards-title {
    font-size: 8px;
    font-weight: bold;
    padding-bottom: 3px;
  }
 
  .card3-header {
    font-size: 9px;
  }
 
  .card3-content {
    font-size: 8px;
    font-weight: 400;
  }
 
  .dropdown-buttons {
    height: 25px;
    width: 80px;
    font-size: 10px;
    padding: 5px 10px;
  }
 
  .dropdown-contents-org {
    width: 80px;
    font-size: 10px;
  }
 
  .custom-buttons {
    width: 80px;
    font-size: 8px;
    padding: 5px;
  }
 
  /* Preserve position for metrics dropdown */
  .session-metrics-container, .dropdown-container-metrics {
    margin-left: auto;
  }
}
 
@media only screen and (min-device-width: 1020px) and (max-device-width: 1200px) {
  .org-appname {
    font-size: 13px;
  }
 
  .org-fonts {
    font-size: 7px;
  }
 
  .org-content1, .org-content2, .org-content3 {
    font-size: 7px;
    position: relative;
    top: 17px;
  }
 
  .orgprog-label {
    margin-left: 12vw;
    margin-top: -2vh;
  }
 
  .card3-header {
    font-size: 10px;
  }
 
  .card3-content {
    font-size: 9px;
    font-weight: 400;
  }
 
  .cards-title {
    font-size: 9px;
    font-weight: bold;
    padding-bottom: 3px;
  }
 
  .dropdown-buttons {
    height: 25px;
    width: 105px;
    font-size: 10px;
  }
 
  .dropdown-contents-org {
    width: 105px;
    font-size: 10px;
  }
 
  .custom-buttons {
    width: 100px;
    font-size: 10px;
  }
 
  /* Preserve position for metrics dropdown */
  .session-metrics-container, .dropdown-container-metrics {
    margin-left: auto;
  }
}
 
@media only screen and (min-device-width: 1201px) and (max-device-width: 1299px) {
  .org-appname {
    font-size: 12px;
  }
 
  .cards-title {
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 3px;
  }
 
  .org-fonts {
    font-size: 10px;
  }
 
  .org-content1, .org-content2, .org-content3 {
    font-size: 10px;
    position: relative;
    top: 17px;
  }
 
  .orgprog-label {
    margin-left: 12vw;
    margin-top: -2.5vh;
  }
 
  .card3-header {
    font-size: 12px;
  }
 
  .card3-content {
    font-size: 10px;
    font-weight: 400;
  }
 
  .dropdown-buttons {
    height: 25px;
    width: 120px;
    font-size: 10px;
  }
 
  .dropdown-contents-org {
    width: 120px;
    font-size: 10px;
  }
 
  .custom-buttons {
    width: 120px;
    font-size: 10px;
  }
 
  /* Preserve position for metrics dropdown */
  .session-metrics-container, .dropdown-container-metrics {
    margin-left: auto;
  }
}
 
@media only screen and (min-device-width: 1300px) and (max-device-width: 1400px) {
  .cards-title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 3px;
  }
 
  .org-fonts {
    font-size: 11px;
  }
 
  .org-content1, .org-content2, .org-content3 {
    font-size: 11px;
    position: relative;
    top: 17px;
  }
 
  .orgprog-label {
    margin-left: 12vw;
    margin-top: -2.5vh;
  }
 
  .card3-header {
    font-size: 11px;
  }
 
  .card3-content {
    font-size: 10px;
    font-weight: 400;
  }
 
  .dropdown-buttons {
    height: 25px;
    width: 135px;
    font-size: 10px;
  }
 
  .dropdown-contents-org {
    width: 135px;
    font-size: 12px;
  }
 
  .custom-buttons {
    width: 125px;
    font-size: 12px;
  }
 
  /* Preserve position for metrics dropdown */
  .session-metrics-container, .dropdown-container-metrics {
    margin-left: auto;
  }
}
 
@media only screen and (min-device-width: 1400px) and (max-device-width: 1600px) {
  .dropdown-buttons {
    height: 25px;
    width: 145px;
    font-size: 10px;
  }
 
  .dropdown-contents-org {
    width: 145px;
    font-size: 10px;
  }
 
  .custom-buttons {
    width: 145px;
    font-size: 12px;
  }
 
  /* Preserve position for metrics dropdown */
  .session-metrics-container, .dropdown-container-metrics {
    margin-left: auto;
  }
}
 
@media only screen and (min-device-width: 1601px) and (max-device-width: 1700px) {
  .cards-title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 3px;
  }
 
  .org-fonts {
    font-size: 12px;
  }
 
  .org-content1, .org-content2, .org-content3 {
    font-size: 12px;
    position: relative;
    top: 17px;
  }
 
  .orgprog-label {
    margin-left: 13vw;
    margin-top: -2.5vh;
  }
 
  .card3-header {
    font-size: 12px;
  }
 
  .card3-content {
    font-size: 12px;
    font-weight: 400;
  }
 
  .dropdown-buttons {
    height: 30px;
    width: 165px;
    font-size: 12px;
  }
 
  .dropdown-contents-org {
    width: 165px;
    font-size: 12px;
  }
 
  .custom-buttons {
    width: 165px;
    font-size: 12px;
  }
 
  .session-note {
    border-radius: 20px;
    background-color: rgb(245, 19, 19);
    width: 14%;
  }
 
  /* Preserve position for metrics dropdown */
  .session-metrics-container, .dropdown-container-metrics {
    margin-left: auto;
  }
}
 
@media only screen and (min-device-width: 1701px) and (max-device-width: 2100px) {
  .cards-title {
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 3px;
  }
 
  .org-fonts {
    font-size: 13px;
  }
 
  .org-content1, .org-content2, .org-content3 {
    font-size: 13px;
    position: relative;
    top: 22px;
  }
 
  .orgprog-label {
    margin-left: 13vw;
    margin-top: -2.5vh;
  }
 
  .card3-header {
    font-size: 13px;
  }
 
  .card3-content {
    font-size: 14px;
    font-weight: 400;
  }
 
  .dropdown-buttons {
    height: 30px;
    width: 160px;
    font-size: 14px;
  }
 
  .dropdown-contents-org {
    width: 160px;
    font-size: 14px;
  }
 
  .metrics-org {
    background-color: #4779EF;
    width: 100%;
    max-height: auto;
    font-size: clamp(10px, 1vw, 10px);
    color: black;
    font-weight: 200;
  }
 
  .custom-buttons {
    width: 160px;
    font-size: 13px;
  }
 
  .session-note {
    border-radius: 20px;
    background-color: rgb(245, 19, 19);
    width: 14%;
  }
 
  /* Preserve position for metrics dropdown */
  .session-metrics-container, .dropdown-container-metrics {
    margin-left: auto;
  }
}
 
@media only screen and (min-device-width: 2101px) and (max-device-width: 4100px) {
  .cards-title {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 4px;
  }
 
  .org-fonts {
    font-size: 15px;
  }
 
  .org-content1,
  .org-content2,
  .org-content3 {
    font-size: 15px;
    position: relative;
    top: 25px;
  }
 
  .orgprog-label {
    margin-left: 12vw;
    margin-top: -2vh;
  }
 
  .card3-header {
    font-size: 15px;
  }
 
  .card3-content {
    font-size: 16px;
    font-weight: 400;
  }
 
  .dropdown-buttons {
    height: 35px;
    width: 100%;
    font-size: 16px;
  }
 
  .down-arrows {
    position: absolute;
    right: 12px;
  }
 
  .dropdown-contents-org{
    width: 100%;
    font-size: 16px;
  }
 
  .metrics-org {
    background-color: #4779EF;
    width: 100%;
    max-height: auto;
    font-size: clamp(12px, 1.2vw, 12px);
    color: black;
    font-weight: 200;
  }
 
  .custom-buttons {
    width: 180px;
    font-size: 15px;
  }
 
  .session-note {
    background-color: rgb(245, 19, 19);
    width: 16%;
  }
 
 
 
}
 
 